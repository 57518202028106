.about-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 5%;
}

.about-section {
  margin-bottom: 4rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.about-section.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.about-section h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  position: relative;
  display: inline-block;
}

.about-section h2::after {
  display: none;
}

.about-section p {
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.about-section ul {
  list-style-type: none;
  padding: 0;
}

.about-section ul li {
  padding: 0.5rem 0;
  position: relative;
  padding-left: 1.5rem;
}

.about-section ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #2c3e50;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-card {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.service-card:hover {
  transform: translateY(-10px) rotateX(5deg) rotateY(-5deg);
  box-shadow: 
    20px 20px 30px rgba(0,0,0,0.1),
    -5px -5px 15px rgba(255,255,255,0.8);
}

.service-card h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.service-card p {
  margin: 0;
  line-height: 1.5;
}

.footer {
  background-color: #2c3e50;
  color: white;
  padding: 2rem 5%;
  text-align: center;
  margin-top: 4rem;
}

.contact-info {
  margin-bottom: 1rem;
}

.contact-info p {
  margin: 0.5rem 0;
}

.copyright {
  font-size: 0.9rem;
  color: #ccc;
}

.founder {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  align-items: start;
}

.founder-image {
  position: relative;
}

.founder-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.founder-info {
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .services-grid {
    grid-template-columns: 1fr;
  }

  .founder {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .founder-image {
    max-width: 400px;
    margin: 0 auto;
  }
} 