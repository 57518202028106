.home {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 4rem 5%;
}

.feature-card {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.feature-card h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.learn-more {
  background: linear-gradient(45deg, #2c3e50, #34495e);
  background-size: 200% 200%;
  animation: gradientBG 5s ease infinite;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
  position: relative;
  overflow: hidden;
}

.learn-more:hover {
  background-color: #34495e;
}

.learn-more::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.learn-more:hover::after {
  transform: translateX(0);
}

.about-preview {
  padding: 4rem 5%;
  background-color: #f5f5f5;
  text-align: center;
}

.testimonials {
  padding: 4rem 5%;
}

.testimonials h2 {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease;
}

.testimonials h2:hover {
  transform: scale(1.05);
}

.testimonials h2::before {
  content: '"';
  position: absolute;
  left: -20px;
  opacity: 0.3;
  font-size: 2.5rem;
}

.testimonials h2::after {
  content: '"';
  position: absolute;
  right: -20px;
  opacity: 0.3;
  font-size: 2.5rem;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.testimonial-card {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  min-height: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.testimonial-card:hover {
  transform: translateY(-10px) rotateX(5deg) rotateY(-5deg);
  box-shadow: 
    20px 20px 30px rgba(0,0,0,0.1),
    -5px -5px 15px rgba(255,255,255,0.8);
}

.quote {
  font-style: italic;
  margin-bottom: 0.75rem;
  flex-grow: 1;
  font-size: 1.05rem;
  line-height: 1.6;
  transform: translateZ(20px);
}

.author-info {
  margin-top: auto;
}

.author {
  font-weight: bold;
  margin-bottom: 0.25rem;
  transform: translateZ(15px);
}

.title {
  color: #666;
  transform: translateZ(15px);
}

.footer {
  background-color: #2c3e50;
  color: white;
  padding: 2rem 5%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.contact-info {
  margin-bottom: 1rem;
}

.contact-info p {
  margin: 0.5rem 0;
}

.copyright {
  font-size: 0.9rem;
  color: #ccc;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  
  .features {
    grid-template-columns: 1fr;
  }
  
  .testimonial-grid {
    grid-template-columns: 1fr;
  }
}

.content-section {
  position: relative;
  padding: 6rem 5%;
  background: linear-gradient(to right, rgba(255,255,255,0.97), rgba(255,255,255,1));
  text-align: center;
  box-shadow: 0 0 20px rgba(0,0,0,0.03);
}

.content-section.alt {
  background: linear-gradient(to right, rgba(245,245,245,0.97), rgba(245,245,245,1));
}

.content-section.alt::before {
  content: '';
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100px;
  background: #f5f5f5;
  transform: skewY(-2deg);
  z-index: -1;
}

.content-section.alt::after {
  content: '';
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 100px;
  background: #f5f5f5;
  transform: skewY(2deg);
  z-index: -1;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  transition: transform 0.3s ease;
  padding: 2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.content-wrapper:hover {
  transform: translateY(-5px);
}

.content-wrapper h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  position: relative;
  display: inline-block;
}

.content-wrapper h2::before {
  content: '🎻';
  margin-right: 10px;
  font-size: 1.5rem;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.content-section:nth-of-type(1) .content-wrapper h2::before {
  content: '🎓';  
}

.content-section:nth-of-type(2) .content-wrapper h2::before {
  content: '⭐'; 
}

.content-section:nth-of-type(3) .content-wrapper h2::before {
  content: '🎻'; 
}

.content-section:nth-of-type(4) .content-wrapper h2::before {
  content: '🎵'; 
}

.content-wrapper h2::after {
  display: none;
}

.content-wrapper p {
  line-height: 1.6;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.content-wrapper.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.content-section:last-child {
  padding-bottom: 8rem;
  margin-bottom: -4rem;
  position: relative;
  overflow: hidden;
}

.content-section:last-child::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, rgba(245,245,245,0.97), rgba(245,245,245,1));
  transform: skewY(-2deg);
  transform-origin: left;
  z-index: -1;
}

@keyframes gradientBG {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}
