.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 5%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  height: 60px;
  width: auto;
  display: block;
}

.menu-toggle {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 100;
  transform: scale(1.2);
}

.hamburger {
  display: block;
  width: 28px;
  height: 3px;
  background: #2c3e50;
  position: relative;
  transition: background 0.2s;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background: #2c3e50;
  transition: transform 0.2s;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

.nav-links {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-width: 200px;
  border-radius: 0 0 4px 4px;
}

.nav-links.active {
  display: flex;
}

.nav-links a {
  text-decoration: none;
  color: #2c3e50;
  font-size: 1.1rem;
  transition: color 0.3s;
  padding: 0.3rem 0;
}

.nav-links a:hover {
  color: #3498db;
}

.menu-toggle[aria-expanded="true"] .hamburger {
  background: transparent;
}

.menu-toggle[aria-expanded="true"] .hamburger::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-toggle[aria-expanded="true"] .hamburger::after {
  transform: rotate(-45deg);
  bottom: 0;
}

.cart-icon {
  transform: scale(1.2);
}

/* Add padding to body or main content to prevent overlap */
body {
  padding-top: 5.5rem;
} 