.cart-icon {
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
}

.cart-icon img {
  width: 24px;
  height: 24px;
}

.cart-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}

.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.cart-overlay.show {
  opacity: 1;
  visibility: visible;
}

.cart-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  background: white;
  padding: 20px 0;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.cart-modal.show {
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.cart-header {
  flex-shrink: 0;
  padding: 0 20px;
}

.cart-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
}

.cart-content::-webkit-scrollbar {
  width: 6px;
}

.cart-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cart-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.cart-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-footer {
  position: static;
  background: white;
  padding: 0 20px 10px;
  margin-top: -10px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.cart-header h2 {
  margin: 0;
}

.close-cart {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

.cart-items {
  flex: 0 1 auto;
  overflow-y: visible;
  margin-bottom: 1rem;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
}

.item-info h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
}

.item-info p {
  margin: 0;
  color: #666;
}

.remove-item {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 10px 0;
  padding: 10px 0;
}

.checkout-button {
  background-color: #2c3e50;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.checkout-button:hover {
  background-color: #34495e;
}

.checkout-button:disabled {
  background-color: #cccccc;
  cursor: wait;
}

.checkout-button:disabled::after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.empty-cart {
  text-align: center;
  color: #666;
}

@media (max-width: 480px) {
  .cart-modal {
    width: 100%;
  }
}

.email-input {
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.email-input:focus {
  outline: none;
  border-color: #007bff;
}

.terms-section {
  padding: 0.5rem;
  border-top: 1px solid #eee;
}

.terms-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  white-space: nowrap;
  text-align: left;
  padding-left: 0;
}

.terms-checkbox input[type="checkbox"] {
  flex-shrink: 0;
  margin: 3px 0 0 0;
}

.terms-button {
  background: none;
  border: none;
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
  display: inline;
  white-space: nowrap;
  margin-left: 0;
  padding-left: 0;
}

.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.terms-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.terms-text {
  margin: 1rem 0;
  font-size: 0.9rem;
  line-height: 1.6;
}

.terms-text h3 {
  margin: 1.5rem 0 0.5rem;
  color: #2c3e50;
}

.close-terms {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0.75rem;
  background: #2c3e50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.close-terms:hover {
  background: #34495e;
}

.student-info-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.form-input:focus {
  outline: none;
  border-color: #666;
}

.form-input::placeholder {
  color: #999;
}

.form-input[required]::placeholder {
  content: attr(placeholder) " *";
}

.cart-section {
  padding: 0 20px;
  margin-bottom: 10px;
}

.student-form-group {
  border: 1px solid #eee;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.student-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.student-header h4 {
  margin: 0;
  color: #2c3e50;
}

.remove-student {
  background: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
}

.add-student-button {
  background: #2c3e50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.add-student-button:hover {
  background: #34495e;
}

.delivery-selection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.delivery-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px;
}

.delivery-option input[type="radio"] {
  margin: 0;
} 