.rentals-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.rentals-hero {
  text-align: center;
  padding: 4rem 2rem;
  color: white;
  position: relative;
  background-color: #2c3e50;
  overflow: hidden;
}

.rentals-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.rentals-hero p {
  font-size: 1.2rem;
  margin: 0;
}

.rentals-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 5%;
}

.rental-info {
  text-align: center;
  margin-bottom: 4rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.rental-info h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.rental-info p {
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.rental-info.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.instrument-category {
  margin-bottom: 4rem;
}

.category-header {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  align-items: flex-start;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-header img {
  width: 300px;
  height: 420px;
  object-fit: cover;
  border-radius: 4px;
}

.category-info {
  flex: 1;
}

.category-info h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.sizes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.size-card {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.size-card h3 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
}

.size-card .price {
  font-weight: bold;
  color: #2c3e50;
  margin: 0 0 1rem 0;
}

.size-card button {
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.size-card button:hover {
  background-color: #34495e;
}

.size-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.accessories-section {
  margin-top: 4rem;
}

.accessories-section h2 {
  color: #2c3e50;
  margin-bottom: 2rem;
}

.accessories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  justify-content: start;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.accessory-card {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 160px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.accessory-card h3 {
  font-size: 1.1rem;
  color: #2c3e50;
  margin: 0;
}

.accessory-card .price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  margin: 0;
}

.accessory-card .one-time {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.accessory-card button {
  margin-top: auto;
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.accessory-card button:hover {
  background-color: #34495e;
}

.accessory-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .category-header {
    flex-direction: column;
    align-items: center;
  }

  .category-header img {
    width: 250px;
    height: 350px;
  }

  .sizes-grid {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .accessories-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 200px));
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .rentals-hero h1 {
    font-size: 2.5rem;
  }

  .category-header img {
    width: 200px;
    height: 280px;
  }

  .accessories-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 170px));
    padding: 1rem;
    gap: 1rem;
  }

  .accessory-card {
    height: 150px;
  }
}

.category-header.fade-in-up {
  opacity: 1;
  transform: translateY(0);
} 