.success-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.success-content {
  text-align: center;
  padding: 4rem 2rem;
  background: none;
  position: static;
  overflow: visible;
}

.success-content h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.success-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.success-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.button {
  display: inline-block;
  background-color: #2c3e50;
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #34495e;
} 