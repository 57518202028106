.purchase-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.purchase-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;
}

.info-section {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-section p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.info-section p:last-child {
  margin-bottom: 0;
}

.purchase-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-button {
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #34495e;
}

@media (max-width: 768px) {
  .purchase-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .purchase-hero h1 {
    font-size: 2.5rem;
  }
}

.purchase-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.purchase-section.fade-in-up {
  opacity: 1;
  transform: translateY(0);
} 