.contact-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.contact-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 5%;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;
}

.contact-info-section {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.welcome-text {
  margin-bottom: 2rem;
  line-height: 1.6;
}

.address-section,
.phone-section {
  margin-top: 2rem;
}

.address-section h2,
.phone-section h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.address-section p,
.phone-section p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.contact-form-section {
  padding: 4rem 5%;
  max-width: 800px;
  margin: 0 auto;
}

.contact-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background-color: #2c3e50;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #34495e;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: wait;
}

.submit-button:disabled::after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .contact-content {
    padding: 2rem 5%;
  }
} 

.contact-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.contact-section.fade-in-up {
  opacity: 1;
  transform: translateY(0);
} 