.hero {
  text-align: center;
  padding: 8rem 2rem 4rem;
  color: white;
  margin-top: -4rem;
  background-size: cover;
  background-position: center;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.hero h1, .hero p {
  position: relative;
  z-index: 2;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero p {
  font-size: 1.2rem;
  margin: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .hero {
    padding: 6rem 1rem 3rem;
    margin-top: -3rem;
  }

  .hero h1 {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  .hero p {
    font-size: 1rem;
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 5rem 1rem 2.5rem;
    margin-top: -2.5rem;
  }

  .hero h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }

  .hero p {
    font-size: 0.9rem;
    line-height: 1.4;
  }
} 